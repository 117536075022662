@charset "UTF-8";
// ==========================================================================
// Human
// 人事ご担当者様へ
// ==========================================================================

// メインビジュアル
@media #{$sp} {
	.p-human-hero {
		background-color: #F8F8FA;
		background-repeat: no-repeat;
		background-position: right bottom;
		background-size: cover;
		
		.p-human-hero__body {
			padding: 4rem 0;
		}
		
		.p-human-hero__title {
			font-size: 2.4rem;
			font-weight: normal;
			color: $colorAccent;
			padding: 0 0 0 $spSpace;
		}
	}
}

@media #{$tbpc} {
	.p-human-hero {
		background-color: #F8F8FA;
		background-repeat: no-repeat;
		background-position: 50% bottom;
		
		.p-human-hero__body {
			padding: 9rem 0;
		}
		
		.p-human-hero__title {
			font-size: 4.8rem;
			font-weight: normal;
			color: $colorAccent;
		}
		
		.p-human-hero__exp {
			font-size: 1.8rem;
			line-height: 1.8;
			margin: 2rem auto 0;
		}
	}
}

.p-human-hero-bottom-exp {
	padding: $spSpace;
}


// 強み
@media #{$sp} {
	.p-human-forte {
		background: $colorAccent;
		color: #fff;
		padding: 0 $spSpace 1.6rem;
		
		.p-human-forte__exp {
			text-align: center;
			margin: 0 0 1.6rem;
		}
		
		.p-human-forte-box {
			
			.p-human-forte-box-item {
				width: 100%;
				text-align: center;
				padding: 0 0 2rem;
			}
		}
		
		.p-human-forte-box-item__icon {
			background: #fff;
			background-position: 50% 50%;
			background-size: cover;
			border-radius: 12.4rem;
			width: 12.4rem;
			height: 12.4rem;
			margin: 0 auto;
		}
		.p-human-forte-box-item__title {
			font-size: 1.6rem;
			font-weight: bold;
			margin: .48rem auto .4rem;
		
		}
		.p-human-forte-box-item__exp {
			font-size: 1.4rem;
			line-height: 1.6;
		}
	}
}

@media #{$tbpc} {
	.p-human-forte {
		background: $colorAccent;
		color: #fff;
		
		.p-human-forte__exp {
			text-align: center;
			margin: 0 0 3.2rem;
		}
		
		.p-human-forte-box {
			@include ib(center);
			padding: 0 1rem;

			.p-human-forte-box-item {
				@include ibChild;
				padding: 0 1rem;
				max-width: 30rem;
				width: 100%;
				vertical-align: top;
			}
		}
		
		.p-human-forte-box-item__icon {
			background: #fff;
			background-position: 50% 50%;
			background-size: cover;
			border-radius: 18.4rem;
			width: 18.4rem;
			height: 18.4rem;
			margin: 0 auto;
		}
		.p-human-forte-box-item__title {
			font-size: 2.4rem;
			font-weight: bold;
			margin: 1.2rem auto 1rem;
		
		}
		.p-human-forte-box-item__exp {
			font-size: 1.4rem;
			line-height: 1.6;
		}
	}
}


// 紹介実績
@media #{$sp} {
	.p-human-result {
		background: $colorBg;
		
		.p-human-result__title {
			color: $colorAccent;
		}
		
		.p-human-result__body {
		
			.p-human-result-data {
				box-sizing: border-box;
				padding: 0 $spSpace;
			}
			
			.p-human-result__cap {
				@include ibChild;
				text-align: right;
				display: block;
				font-size: 1.3rem;
				color: $colorBaseSub;
				margin: .6rem $spSpace 0 0;
			}
		}
		
		.p-human-result-data-item {
			background: #fff;
			table-layout: auto;
			padding: 1rem 0;
			text-align: center;
			
			.p-human-result-data-item__title {
				vertical-align: middle;
				font-weight: bold;
				padding: 0 3.2rem 0 0;
			}

			.p-human-result-data-item__contents {
				vertical-align: middle;
				font-family: $fontRobot;
				color: $colorAccent;
				font-size: 6.72rem;
				line-height: 1;

				span {
					font-size: 2.88rem;
				}
			}
		}
		
		.p-human-result-data-group {
			background: #fff;
			text-align: center;
			
			.p-human-result-data-group-top {
			
				.p-human-result-data-group-top-item {
					padding: 1rem 0 1.2rem;
					
					.p-human-result-data-group-top-item__title {
						font-weight: bold;
					}
					.p-human-result-data-group-top-item__contents {
						font-family: $fontRobot;
						color: $colorSubLinks;
						font-size: 4.32rem;
						line-height: 1;
						
						span {
							font-size: 1.92rem;
						}
					}
				}
			}
			
			.p-human-result-data-group-bottom {
				border-top: #E5E5E5 solid 1px;
				padding: 1rem 0 1rem;
				
				ul {
					@include ib(center);
					list-style: none;
					
					li {
						padding: 0 0 2rem;
						
						&:last-of-type {
							padding: 0 0 3rem;
						}
					}
				}
				
				.p-human-result-data-group-bottom-item {
					@include ibChild;
					vertical-align: top;
					
					.p-human-result-data-group-bottom-item__title {
						font-weight: bold;
						padding: 0 0 .8rem;
					}
					.p-human-result-data-group-bottom-item__figure {
						max-width: 24rem;
						width: 100%;
					}
				}
			}
		}
	}
}

@media #{$tbpc} {
	.p-human-result {
		background: $colorBg;
		
		.p-human-result__title {
			color: $colorAccent;
		}
		
		.p-human-result__body {
			@include ib(left);

			.p-human-result-data {
				@include ibChild;
				box-sizing: border-box;
				padding: 0 0 0 1.2rem;
				width: 50%;

				&:first-of-type {
					padding: 0 1.2rem 0 0;
				}

				&:last-of-type {
					width: 100%;
					padding: 2.4rem 0 0;
				}
			}

			.p-human-result__cap {
				@include ibChild;
				text-align: right;
				display: block;
				font-size: 1.3rem;
				color: $colorBaseSub;
				margin: .6rem 0 0;
			}
		}

		.p-human-result-data-item {
			@include ib(center);
			background: #fff;
			table-layout: auto;
			padding: 2rem 0;

			.p-human-result-data-item__title {
				@include ibChild;
				vertical-align: middle;
				font-weight: bold;
				padding: 0 3.2rem 0 0;
			}

			.p-human-result-data-item__contents {
				@include ibChild;
				vertical-align: middle;
				font-family: $fontRobot;
				color: $colorAccent;
				font-size: 11.2rem;
				line-height: 1;

				span {
					font-size: 4.8rem;
				}
			}
		}

		.p-human-result-data-group {
			background: #fff;
			padding: 0 4rem;

			.p-human-result-data-group-top {
				@include ib(center);

				.p-human-result-data-group-top-item {
					@include ibChild;
					vertical-align: middle;
					padding: 2rem 0;

					&:last-of-type {
						padding: 0 0 0 6.4rem;
					}

					.p-human-result-data-group-top-item__title {
						@include ibChild;
						vertical-align: middle;
						font-weight: bold;
						padding: 0 3.2rem 0 0;
					}
					.p-human-result-data-group-top-item__contents {
						@include ibChild;
						vertical-align: middle;
						font-family: $fontRobot;
						color: $colorSubLinks;
						font-size: 7.2rem;
						line-height: 1;

						span {
							font-size: 3.2rem;
						}
					}
				}
			}

			.p-human-result-data-group-bottom {
				border-top: #E5E5E5 solid 1px;
				padding: 3.6rem 0 4.9rem;

				ul {
					@include ib(center);
					list-style: none;

					li {
						padding: 0 3rem;

						&:first-of-type {
							padding: 0 3rem 0 0;
						}

						&:last-of-type {
							padding: 0 0 0 3rem;
						}
					}
				}

				.p-human-result-data-group-bottom-item {
					@include ibChild;
					vertical-align: top;

					.p-human-result-data-group-bottom-item__title {
						font-weight: bold;
						padding: 0 0 1.8rem;
					}
					.p-human-result-data-group-bottom-item__figure {
					}
				}
			}
		}
	}
}


// お客様の声
@media #{$sp} {
	.p-human-customer {
		.p-human-customer__title {
			margin: 0 0 3rem;
		}
		.p-human-customer__body {
			
			.p-human-customer-item {
				border-bottom: #DEE2E8 solid 2px;
				padding: 0 0 4rem;
				margin: 0 0 4rem;
				
				&:last-of-type {
					border-bottom: none;
					padding: 0;
					margin: 0;
				}
				
				// 会社名
				.p-human-customer-item__title {
					text-align: center;
					color: $colorAccent;
					font-size: 2.4rem;
					font-weight: normal;
					margin: 0 $spSpace 1.6rem;
				}
				
				// 記事内
				.p-human-customer-item-article {
					margin: 0 $spSpace;
					
					.p-human-customer-item-article-item--logo {
						max-width: 24rem;
						width: 100%;
						text-align: center;
					}
					
					.p-human-customer-item-article-item {
						box-sizing: border-box;
						margin: 0 auto 2.4rem;
						
						.p-human-customer-item-article-item__title {
							font-size: 1.6rem;
							color: $colorSubLinks;
							font-weight: bold;
							margin: 0 0 .8rem;
						}
						.p-human-customer-item-article-item__text {
							font-size: 1.4rem;
							line-height: 1.8;
						}
					}
				}

				// 担当のコメント
				.p-human-customer-item-comment {
					@include table(100%);
					table-layout: fixed;
					padding: 0 $spSpace;
					box-sizing: border-box;
					
					.p-human-customer-item-comment__thumb {
						@include tableCell(middle);
						background: $colorBg;
						width: 8rem;
						padding: 0 0 0 1rem;
						box-sizing: border-box;
						
						img {
							border: $colorBg solid 2px;
						}
					}
					.p-human-customer-item-comment__balloon {
						@include tableCell(middle);
					}
					.p-human-customer-item-comment-balloon__body {
						background: $colorBg;
						border-radius: 2px;
						position: relative;
						box-sizing: border-box;
						padding: 1.2rem;
					}
					.p-human-customer-item-comment-balloon__catch {
						font-weight: bold;
						margin: 0 0 .4rem;
					}
					.p-human-customer-item-comment-balloon__career {
						font-size: 1.1rem;
					}
					.p-human-customer-item-comment-balloon__name {
						color: $colorAccent;
						font-size: 1.1rem;
					}
				}
			}
		}
	}
}

@media #{$tbpc} {
	.p-human-customer {
		.p-human-customer__title {
			margin: 0 0 3rem;
		}
		.p-human-customer__body {

			.p-human-customer-item {
				border-bottom: #DEE2E8 solid 2px;
				padding: 0 0 4rem;
				margin: 0 0 4rem;

				&:last-of-type {
					border-bottom: none;
					padding: 0;
					margin: 0;
				}

				// 会社名
				.p-human-customer-item__title {
					text-align: center;
					color: $colorAccent;
					font-size: 3.6rem;
					font-weight: normal;
					margin: 0 0 1.6rem;
				}

				// 記事内
				.p-human-customer-item-article {
					@include ib(left);

					.p-human-customer-item-article-item {
						@include ibChild;
						vertical-align: top;
						width: 50%;
						box-sizing: border-box;
						padding: 0 2rem 0 0;
						margin: 0 0 4rem;

						&:nth-of-type(odd) {
							padding: 0 0 0 2rem;
						}

						&:nth-child(2) {
							width: 65%;
						}
						
						.p-human-customer-item-article-item__title {
							font-size: 2.2rem;
							color: $colorSubLinks;
							font-weight: bold;
							margin: 0 0 1rem;
						}
						.p-human-customer-item-article-item__text {
							font-size: 1.4rem;
							line-height: 1.8;
						}
						
						&.p-human-customer-item-article-item--logo {
							width: 35%;
							padding: 0 2rem 0 0;
							margin: 0;
							vertical-align: middle;
						}
					}
				}

				// 担当のコメント
				.p-human-customer-item-comment {
					@include table(100%);
					table-layout: fixed;

					.p-human-customer-item-comment__thumb {
						@include tableCell(middle);
						width: 21.6rem;

						img {
							border: $colorBg solid 2px;
						}
					}
					.p-human-customer-item-comment__balloon {
						@include tableCell(middle);
					}
					.p-human-customer-item-comment-balloon__body {
						background: $colorBg;
						border-radius: 2px;
						position: relative;
						box-sizing: border-box;
						padding: 1.2rem 3.2rem;

						&:before {
							content: "";
							background: url(images/human_arrow_balloon.png) no-repeat scroll 0 0;
							display: block;
							width: 2.5rem;
							height: 3.2rem;
							position: absolute;
							left: -2.5rem;
							bottom: 4rem;
						}
					}
					.p-human-customer-item-comment-balloon__catch {
						font-size: 1.8rem;
						line-height: 1.8;
						font-weight: bold;
						margin: 0 0 .4rem;
					}
					.p-human-customer-item-comment-balloon__career {
						font-size: 1.4rem;
					}
					.p-human-customer-item-comment-balloon__name {
						color: $colorAccent;
						font-size: 1.4rem;
					}
				}
			}
		}
	}
}


// お問い合わせ
.p-human-contact {
	border-top: #eee solid 2px;
	background: #FAFAFA;
	
}

