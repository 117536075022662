@charset "UTF-8";
// ==========================================================================
// Wordpress
// TinyMCEの仕様によるWPのネイティブCSSの設定
// ==========================================================================

.aligncenter {
	display: block;
	margin: 0 auto;
}

.alignright {
	float: right;
}

.alignleft {
	float: left;
}


