@charset "UTF-8";
// ==========================================================================
// Career
// 転職希望の方へ
// ==========================================================================

// メインビジュアル
@media #{$sp} {
	.p-career-hero {
		background-position: 50% 50%;
		background-repeat: no-repeat;
		background-size: cover;
		
		.p-career-hero__body {
			padding: 2.4rem 0;
			text-align: center;
			color: #fff;
		}
		
		.p-career-hero__title {
			font-size: 2.4rem;
			font-weight: normal;
			text-shadow: 0px 1px 2px rgba(0,0,0,0.50);
		}
		
		.p-career-hero__exp {
			font-size: 1.2rem;
			text-shadow: 0px 1px 2px rgba(0,0,0,0.50);
			margin: .8rem $spSpace 0;
			
			br {
				display: none;
			}
		}
	}
}
@media #{$tbpc} {
	.p-career-hero {
		background-repeat: no-repeat;
		background-size: cover;
		
		.p-career-hero__body {
			padding: 13.6rem 0;
			text-align: center;
			color: #fff;
		}
		
		.p-career-hero__title {
			font-size: 4.8rem;
			font-weight: normal;
			text-shadow: 0px 1px 2px rgba(0,0,0,0.50);
		}
		
		.p-career-hero__exp {
			font-size: 1.8rem;
			line-height: 1.8;
			text-shadow: 0px 1px 2px rgba(0,0,0,0.50);
			margin: 2rem auto 0;
		}
	}
}


// サービス
@media #{$sp} {
	.p-career-service__body {
		margin: 0 $spSpace;
		
		.p-career-service-item {
			margin: 0 0 2rem;
			
			&:last-child {
				margin: 0;
			}
		}
		.p-career-service-item__thumb {
			padding: 0 0 1rem 0;
			text-align: center;
			
			img {
				border: $colorBg solid 4px;
			}
		}
		
		.p-career-service-item__body {
			overflow: hidden;
		}
		.p-career-service-item__title {
			color: $colorAccent;
			font-size: 1.6rem;
			margin: 0 0 1rem;
			text-align: center;
		}
		.p-career-service-item__exp {
			margin: 0 0 1.6rem;
		}
		
		.p-career-service-item__button {
			text-align: center;
		}
	}
}

@media #{$tbpc} {
	.p-career-service__body {
		padding: 2.4rem 0 0;
		
		.p-career-service-item {
			@include flow(100%);
			margin: 0 0 4.8rem;
			
			&:last-child {
				margin: 0;
			}
		}
		.p-career-service-item__thumb {
			float: left;
			padding: 0 4rem 0 0;
			
			img {
				border: $colorBg solid 4px;
			}
		}
		
		.p-career-service-item__body {
			overflow: hidden;
		}
		.p-career-service-item__title {
			color: $colorAccent;
			font-size: 2.2rem;
			margin: 0 0 1rem;
		}
		.p-career-service-item__exp {
			margin: 0 0 1.6rem;
		}
	}
}


// ご支援実績
.p-career-support {
	background: $colorBg;
}

@media #{$sp} {
	.p-career-support {
		.p-career-support-item {
			margin: 0 $spSpace 2rem;
			
			.p-career-support-item__body {
				background: #fff;
				box-shadow: 2px 2px 0px 0px #CECFD5;
				height: 100%;
				padding: 1rem;
				box-sizing: border-box;
			}
			
			.p-career-support-item__head {
				margin: 0 0 1.6rem;
				padding: .4rem 0 .4rem 6.4rem;
				
				.p-career-support-item__name {
					font-size: 1.6rem;
				}
				
				.p-career-support-item__profile {
					display: block;
				}
			}
			
			.p-career-support-item-career {
				background: #FAFAFA;
				padding: .8rem;
				margin: 0 0 2rem;
				font-size: 1.4rem;
			}
			
			.p-career-support-item-career__box--before {
				background: url(images/career_arrow@2x.png) no-repeat scroll 1.3rem bottom;
				background-size: 14px 20px;
				padding: 0 0 2rem;
				margin: 0 0 .2rem;
			}
			
			.p-career-support-item-career__box--after {
				font-weight: bold;
			}
			
			.p-career-support-item-career__title {
				display: inline-block;
				padding: 0 3rem 0 0;
				color: $colorGray;
				font-weight: bold;
				
				&.p-career-support-item-career__title--after {
					color: #E03662;
				}
			}
			
			.p-career-support-item-box {
				margin: 0 0 1.2rem;
				
				.p-career-support-item-box__title {
					color: $colorSubLinks;
					font-weight: bold;
					margin: 0 0 .4rem;
				}
				.p-career-support-item-box__exp {
					font-size: 1.2rem;
				}
			}
		}
		
		// 男性
		.p-career-support-item--man {
			.p-career-support-item__head {
				background: url(images/career_ico_man@2x.png) no-repeat scroll 0 50%;
				background-size: 48px 48px;
			}
			
			.p-career-support-item__name {
				color: $colorSexMan;
			}
		}
		
		// 女性
		.p-career-support-item--woman {
			.p-career-support-item__head {
				background: url(images/career_ico_woman@2x.png) no-repeat scroll 0 50%;
				background-size: 48px 48px;
			}
			
			.p-career-support-item__name {
				color: $colorSexWoman;
			}
			
		}
	}
}

@media #{$tbpc} {
	.p-career-support {
		.p-career-support-group {
			@include table(100%);
			table-layout: fixed;
			margin: 0 0 4rem;
			height: 100%;
			
			&:last-child {
				margin: 0;
			}
		}
		
		.p-career-support-item {
			@include tableCell(top);
			box-sizing: border-box;
			width: 50%;
			padding: 0 2rem 0 0;
			height: 100%;
			
			&:nth-child(even) {
				padding: 0 0 0 2rem;
			}
			
			.p-career-support-item__body {
				background: #fff;
				box-shadow: 4px 4px 0px 0px #CECFD5;
				height: 100%;
				padding: 2.4rem;
				box-sizing: border-box;
			}
			
			.p-career-support-item__head {
				margin: 0 0 1.6rem;
				padding: .4rem 0 .4rem 7rem;
				
				.p-career-support-item__name {
					font-size: 2.2rem;
				}
				
				.p-career-support-item__profile {
				}
			}
			
			.p-career-support-item-career {
				background: #FAFAFA;
				padding: 1rem 2rem;
				margin: 0 0 2rem;
				font-size: 1.4rem;
			}
			
			.p-career-support-item-career__box--before {
				background: url(images/career_arrow@2x.png) no-repeat scroll 1.3rem bottom;
				background-size: 14px 20px;
				padding: 0 0 2rem;
				margin: 0 0 .2rem;
			}
			
			.p-career-support-item-career__box--after {
				font-weight: bold;
			}
			
			.p-career-support-item-career__title {
				display: inline-block;
				padding: 0 3rem 0 0;
				color: $colorGray;
				font-weight: bold;
				
				&.p-career-support-item-career__title--after {
					color: #E03662;
				}
			}
			
			.p-career-support-item-box {
				margin: 0 0 1.2rem;
				
				.p-career-support-item-box__title {
					color: $colorSubLinks;
					font-size: 1.6rem;
					font-weight: bold;
					margin: 0 0 .4rem;
				}
				.p-career-support-item-box__exp {
					font-size: 1.4rem;
				}
			}
		}
		
		// 男性
		.p-career-support-item--man {
			.p-career-support-item__head {
				background: url(images/career_ico_man@2x.png) no-repeat scroll 0 50%;
				background-size: 48px 48px;
			}
			
			.p-career-support-item__name {
				color: $colorSexMan;
			}
		}
		
		// 女性
		.p-career-support-item--woman {
			.p-career-support-item__head {
				background: url(images/career_ico_woman@2x.png) no-repeat scroll 0 50%;
				background-size: 48px 48px;
			}
			
			.p-career-support-item__name {
				color: $colorSexWoman;
			}
			
		}
	}
}


// コンサルタント紹介
@media #{$sp} {
	.p-career-consultant {
		.p-career-consultant__body {
			box-sizing: border-box;
			margin: 0 $spSpace;
			
			.p-career-consultant__name {
				font-weight: bold;
				font-size: 1.6rem;
				text-align: center;
				margin: 0 0 .8rem;
			}
			
			.p-career-consultant__profile {
				margin: 0 0 1.2rem;
			}
			
			.p-career-consultant__link {
				text-align: center;
				margin: 0 0 1.6rem;
			}
			
			.p-career-consultant__img {
				text-align: center;
				
				img {
					max-width: 20rem;
					width: 100%;
				}
			}
		}
	}
}

@media #{$tbpc} {
	.p-career-consultant {
		.p-career-consultant__body {
			@include flow(100%);
			box-sizing: border-box;
			padding: 0 8rem 0 2.4rem;

			.p-career-consultant__inner {
				width: 59rem;
				float: left;
			}

			.p-career-consultant__name {
				font-weight: bold;
				font-size: 2.2rem;
				margin: 0 0 .8rem;
			}

			.p-career-consultant__profile {
				margin: 0 0 1.2rem;
			}

			.p-career-consultant__img {
				float: right;
			}
		}
	}
}


// ご相談の流れ
.p-career-flow {
	background: $colorAccent;
}

@media #{$sp} {
	.p-career-flow {
		.p-career-flow__body {
			list-style: none;
			margin: 0 $spSpace;
			
			.p-career-flow-item {
				background: url(images/career_arrow_flow.png) no-repeat scroll 50% bottom;
				background-size: 18px 10px;
				padding: 0 0 2rem;
				margin: 0 0 1.2rem;
				
				&:last-child {
					background: none;
					padding: 0;
					margin: 0;
				}
			}
			
			.p-career-flow-item__inner {
				box-sizing: border-box;
				background: #fff;
				width: 100%;
				position: relative;
				padding: 1rem;
			}
			
			.p-career-flow-item__panel {
				color: $colorSubLinks;
				font-weight: bold;
			}
			
			.p-career-flow-item__title {
				font-size: 1.6rem;
				font-weight: bold;
				margin: 0 0 .2rem;
			}
		}
	}
}

@media #{$tbpc} {
	.p-career-flow {
		.p-career-flow__body {
			list-style: none;
			
			.p-career-flow-item {
				background: url(images/career_arrow_flow.png) no-repeat scroll 50% bottom;
				padding: 0 0 3.4rem;
				margin: 0 0 1.5rem;
				
				&:last-child {
					background: none;
					padding: 0;
					margin: 0;
				}
			}
			
			.p-career-flow-item__body {
				@include table(100%);
				padding: 0 0 0 .8rem;
				box-sizing: border-box;
			}
			.p-career-flow-item__inner {
				@include tableCell(middle);
				box-sizing: border-box;
				background: #fff;
				width: 100%;
				height: 16rem;
				padding: 0 0 0 13.6rem;
				position: relative;
			}
			
			.p-career-flow-item__panel {
				@include tableCell(middle);
				background: $colorSubLinks;
				box-sizing: border-box;
				width: 10.4rem;
				height: 10.4rem;
				text-align: center;
				color: #fff;
				position: absolute;
				left: -.8rem;
				top: 0;
				bottom: 0;
				margin: auto 0;
				font-size: 2rem;
				line-height: 1;
				padding: .8rem 0 0;
				
				span {
					font-size: 6.2rem;
					display: block;
					padding: .4rem 0 0;
				}
				
				&:before {
					content: "";
					width: 0;
					height: 0;
					border-style: solid;
					border-width: 0 0 8px 8px;
					border-color: transparent transparent #0D7B7D transparent;
					position: absolute;
					left: 0;
					top: -8px;
				}
				
				&:after {
					content: "";
					width: 0;
					height: 0;
					border-style: solid;
					border-width: 0 8px 8px 0;
					border-color: transparent #0D7B7D transparent transparent;
					position: absolute;
					left: 0;
					bottom: -8px;
				}
			}
			
			.p-career-flow-item__title {
				font-size: 2.4rem;
				font-weight: bold;
				margin: 0 0 .2rem;
			}
		}
	}
}

