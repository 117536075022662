@charset "UTF-8";
// ==========================================================================
// Footer
// フッターの記述
// ==========================================================================

@media #{$sp} {
	
	.footer {
		border-top: $colorAccent solid 2.5px;
	}
	
	.footer-site-info__logo {
		text-align: center;
		padding: 1.2rem 0 0;
		
		img {
			width: 12.8rem;
			height: 2.5rem;
		}
	}
	
	.footer-site-info__body {
		font-size: 1rem;
		line-height: 1.6;
		text-align: center;
		margin: 1rem auto 1.5rem;
	}
	
	.footer__links-group {
		text-align: center;
		margin: 0 0 2.4rem;
		
		ul {
			list-style: none;
			
			.footer-links__item {
				display: inline-block;
				padding: 0 1.6rem;
			}
		}
	}
	
	.copyright {
		background: $colorBg;
		text-align: center;
		font-size: .8rem;
		padding: 1.2rem 0 2.4rem;
	}
}

@media #{$tbpc} {
	
	.footer {
		border-top: $colorAccent solid 5px;
		
		.footer__body {
			@include table(100%);
			table-layout: fixed;
		}
	}
	
	.footer__site-info {
		@include tableCell(top);
		padding: 2rem 0;
		
		.footer__site-info__body {
			@include table(100%);
			table-layout: fixed;
		}
		
		.footer-site-info__logo {
			@include tableCell(middle);
			
			img {
				width: 24.5rem;
				height: 4.8rem;
			}
		}
		.footer-site-info__body {
			@include tableCell(middle);
			font-size: 1.4rem;
			line-height: 1.7;
			padding: 0 0 0 3.2rem;
			white-space: nowrap;
		}
	}
	
	.footer__links-group {
		@include tableCell(middle);
		text-align: right;
		
		ul {
			list-style: none;
			
			.footer-links__item {
				display: inline-block;
				padding: 0 0 0 3.6rem;
			}
		}
	}
	
	.copyright {
		background: $colorBg;
		text-align: center;
		font-size: 1.4rem;
		letter-spacing: .1em;
		padding: 2rem 0 6rem;
	}
}

