@charset "UTF-8";
// ==========================================================================
// Headline
// 見出しの設定
// ==========================================================================

.c-headline {
	@media #{$sp} {
		font-size: 2rem;
		text-align: center;
		font-weight: bold;
		padding: 2.2rem 0 0;
		margin: 0 0 1.3rem;
		line-height: 1.6;
	}
	
	@media #{$tbpc} {
		font-size: 4.8rem;
		text-align: center;
		font-weight: normal;
		padding: 5rem 0 0;
		margin: 0 0 2.6rem;
		line-height: 1.5;
	}
	
	span {
		@media #{$sp} {
			font-size: 1.1rem;
			display: block;
		}
		
		@media #{$tbpc} {
			font-size: 1.8rem;
			display: block;
			text-align: center;
		}
	}
	
	&.c-headline--en {
		font-family: $fontNoto;
		font-weight: 300;
		letter-spacing: 4px;
		
		span {
			letter-spacing: normal;
			
			@media #{$sp} {
				font-family: $fontHirakaku;
				font-weight: normal;
			}
			@media #{$tbpc} {
				font-family: $fontYugo;
				font-weight: normal;
			}
		}
	}
	
	&.c-headline--white {
		color: #fff;
	}
}

