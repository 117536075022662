@charset "UTF-8";
// ==========================================================================
// Form
// フォームの設定
// ==========================================================================

input,
textarea {
	@media #{$sp} {
		font-family: $fontHirakaku;
		border-radius: 0;
		-webkit-appearance: none;
	}
	
	@media #{$tbpc} {
		font-family: $fontYugo;
	}
}

input[type=text],
input[type=email],
input[type=tel] {
	@include placeholderColor(#ccc);
	background: #fff;
	box-sizing: border-box;
	width: 100%;
	
	&.c-form-box__input--small {
		width: 15.2rem;
	}
	
	@media #{$sp} {
		border: $colorBorder solid 2px;
		padding: .6rem .8rem;
		font-size: 1.4rem;
	}
	@media #{$tbpc} {
		border: $colorBorder solid 3px;
		padding: .3rem 1.2rem;
		font-size: 1.8rem;
	}
}

textarea {
	@include placeholderColor(#ccc);
	background: #fff;
	box-sizing: border-box;
	width: 100%;
	
	@media #{$sp} {
		border: $colorBorder solid 2px;
		padding: .6rem .8rem;
		height: 10rem;
		font-size: 1.3rem;
	}
	@media #{$tbpc} {
		border: $colorBorder solid 3px;
		padding: .96rem 1.6rem;
		height: 16.8rem;
		font-size: 1.4rem;
	}
}

input[type=submit] {
	@extend a.c-action-button;
	border: none;
	cursor: pointer;
	@media #{$sp} {
		margin: 2rem auto 0;
		font-weight: bold;
		width: 100%;
	}
	@media #{$tbpc} {
		margin: 4rem auto 0;
	}
}
input[type=submit].c-form-back__button {
	background: #B5BAC2;
	
	@media #{$sp} {
		box-shadow: 0px 2px 0px 0px #89919E;
		margin: 2.4rem auto 0;
		font-size: 1.3rem;
		padding: .6rem 0;
	}
	@media #{$tbpc} {
		box-shadow: 0px 4px 0px 0px #89919E;
		margin: 4rem auto 0;
		font-size: 2.2rem;
		padding: .6rem 0;
	}
}

select {
	//width: 100%;
	appearance: none;
	border-radius: 0;
	margin: 0;
	padding: 0;
	background: url(images/icon_select@2x.png) no-repeat scroll right 50%;
	background-size: 22px 10px;
	vertical-align: middle;
	font-size: inherit;
	color: inherit;
	box-sizing: border-box;
	
	@media #{$sp} {
		border: $colorBorder solid 2px;
		padding: .6rem .8rem;
		background-size: 16px 6px;
	}
	@media #{$tbpc} {
		border: $colorBorder solid 3px;
		padding: .96rem 1.6rem;
		font-size: 1.6rem;
		min-width: 12rem;
	}
}

@media #{$sp} {
	select[name="year"] {
		min-width: 6.6rem;
	}
	select[name="month"] {
		min-width: 5rem;
	}
	select[name="day"] {
		min-width: 5rem;
	}
}




// box
.c-form-box {
	@media #{$sp} {
		margin: 0 0 1.6rem;
	}
	@media #{$tbpc} {
		margin: 0 0 2.4rem;
	}
	
	.c-form-box-inner {
		display: inline-block;
		vertical-align: bottom;
		padding: 0 2.4rem 0 0;
	}
	
	.c-form-box__title {
		margin: 0 0 .2rem;
		font-weight: bold;
		
		@media #{$sp} {
			font-size: 1.3rem;
		}
		@media #{$tbpc} {
			font-size: 1.8rem;
		}
	}
	
	.c-form-icon {
		color: #989BA1;
		display: inline-block;
		vertical-align: middle;
		line-height: 1;
		
		@media #{$sp} {
			font-size: 1.2rem;
			margin: 0 0 0 .8rem;
		}
		@media #{$tbpc} {
			font-size: 1.4rem;
			margin: 0 0 0 1.2rem;
		}
	}
	
	.c-form-icon--alert {
		@extend .c-form-icon;
		color: $colorAlert;
	}
	
	&.c-form-box--center {
		text-align: center;
	}
	
	.error {
		background: $colorAlert;
		display: block;
		padding: 0.4rem 1rem;
		color: #fff;
		font-weight: bold;
		font-size: 100%;
		margin: .8rem 0 0;
	}
}

// attention
.c-form-att {
	text-align: center;
	
	@media #{$tbpc} {
		margin: 0 auto -2rem;
	}
}

.mw_wp_form_confirm {
	.c-form-att {
		display: none;
	}
}

