@charset "UTF-8";

// ==========================================================================
// Foundation
// ==========================================================================

@import url(//fonts.googleapis.com/earlyaccess/notosansjapanese.css);
@import url(//fonts.googleapis.com/css?family=Roboto);


@import "foundation/_variable";
@import "foundation/_mixin";
@import "foundation/_reset";
@import "foundation/_base";
@import "foundation/_wp";


// ==========================================================================
// Plugins
// ==========================================================================

//プラグイン用のファイルを読み込み
//@import "plugins/_plugin_name.scss";


// ==========================================================================
// Layout
// ==========================================================================

@import "layout/_footer";
@import "layout/_header";
@import "layout/_main";


// ==========================================================================
// Object
// ==========================================================================

// -----------------------------------------------------------------
// Component
// -----------------------------------------------------------------

@import "object/component/_headline";
@import "object/component/_box";
@import "object/component/_links";
@import "object/component/_form";


// -----------------------------------------------------------------
// Project
// -----------------------------------------------------------------

@import "object/project/_home.scss";
@import "object/project/_career.scss";
@import "object/project/_human.scss";


// -----------------------------------------------------------------
// Utility
// -----------------------------------------------------------------

@import "object/utility/_module";


