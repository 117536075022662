@charset "UTF-8";
// ==========================================================================
// Base
// ここには基本設定部分のみを記載
// ==========================================================================

html {
	font-size: 62.5%;
	height: 100%;
}

body {
	color: $colorBase;
	width: 100%;
	height: 100%;
	box-sizing: border-box;
	word-wrap: break-word;
	-webkit-text-size-adjust: 100%;
	-webkit-print-color-adjust: exact;
	
	@media #{$sp} {
		@include spBaseFont;
	}
	
	@media #{$tbpc} {
		@include pcBaseFont;
	}
}


// リンク色
a {
	color: $colorLinks;
	text-decoration: underline;
}

a:visited {
	color: $colorSubLinks;
	text-decoration: underline;
}

a:hover {
	color: $colorLinks;
	text-decoration: none;
}

a:active {
	color: $colorLinks;
	text-decoration: none;
}


// コンテナ
.container {
	@media #{$tbpc} {
		width: $containerWidth;
		margin: 0 auto;
	}
}
.container--small {
	@media #{$sp} {
		margin: 0 $spSpace;
	}
	@media #{$tbpc} {
		width: $containerWidthSmall;
		margin: 0 auto;
	}
}

// hidden
.hidden {
	display: none;
}

.hidden--sp {
	@media #{$sp} {
		display: none;
	}
}

.hidden--pc {
	@media #{$tbpc} {
		display: none;
	}
}
