@charset "UTF-8";
// ==========================================================================
// Mixin
// グローバルなmixinはここに格納します。それ以外はそのscssファイルのみで完結。
// ==========================================================================


// -----------------------------------------------------------------
// base font setting
// -----------------------------------------------------------------

// spのベースフォント
@mixin spBaseFont {
	font-family: $fontHirakaku;
	font-size: 1.3rem;
	line-height: 1.8;
}

// pcのベースフォント
@mixin pcBaseFont {
	font-family: $fontYugo;
	font-size: 1.6rem;
	line-height: 1.88;
}


// -----------------------------------------------------------------
// レイアウト関連
// -----------------------------------------------------------------

// inline-blockレイアウト
@mixin ib($value) {
	text-align: $value;
	letter-spacing: -.40em;
}

@mixin ibChild {
	display: inline-block;
	letter-spacing: normal;
}

//tableレイアウト
@mixin table($value) {
	display: table;
	width: $value;
}

@mixin tableCell($value) {
	display: table-cell;
	vertical-align: $value;
}

//overflow型cleafix
@mixin flow($value) {
	width: $value;
	overflow: hidden;
}

//clearfix(Compass使わないのでここに書きます)
@mixin clearfix {
	zoom: 1;
	
	&:before,
	&:after {
		content: "";
		display: table;
	}
	
	&:after {
	clear: both;
	}
}

//リンクアローのデフォルト設定
@mixin linksArrow {
	content: "";
	display: block;
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	margin: auto;
}


// -----------------------------------------------------------------
// アニメーション
// -----------------------------------------------------------------

//アルファアニメーション
@mixin animeAlpha {
	transition: opacity 0.2s ease-out;
}

@mixin animeAlphaHover($value) {
	opacity: $value / 100;
	filter:alpha(opacity=$value);
}

//背景アニメーション
@mixin animeBg {
	transition: background 0.2s ease-out;
}

//カラーアニメーション
@mixin animeColor {
	transition: color 0.2s ease-out;
}

// allアニメーション
@mixin animeAll {
	transition: all 0.2s ease-out;
}


// -----------------------------------------------------------------
// その他
// -----------------------------------------------------------------

// プレースホルダ
@mixin placeholderColor($value) {
	&:placeholder-shown {
		color: $value;
	}
	&::-webkit-input-placeholder {
		color:$value;
	}
	&:-moz-placeholder {
		color:$value;
		opacity: 1;
	}
	&::-moz-placeholder {
		color:$value;
		opacity: 1;
	}
	&:-ms-input-placeholder {
		color:$value;
	}
}

