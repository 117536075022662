@charset "UTF-8";
// ==========================================================================
// Header
// ヘッダーの記述。
// ==========================================================================

.header {
	background: #fff;
	position: relative;
	box-shadow: 0px 2px 3px 0px rgba(0,0,0,0.12);
	z-index: 100;
}

@media #{$sp} {
	.header__body {
		.header__logo {
			text-align: center;
			padding: .8rem 0;
			vertical-align: top;
			
			img {
				width: 12.8rem;
				height: 2.5rem;
			}
		}
		
		.header-nav {
			display: none;
		}
		
	}
}

@media #{$tbpc} {
	.header__body {
		@include table(100%);
		padding: 2rem 0;
		
		.header__logo {
			@include tableCell(middle);
			width: 24.5rem;
			
			img {
				width: 24.5rem;
				height: 4.8rem;
			}
		}
		
		.header-nav {
			@include tableCell(middle);
		}
		
		.header-nav__body {
			@include ib(right);
			list-style: none;
			
			li {
				@include ibChild;
				padding: 0 0 0 4.8rem;
				
				a {
					@include animeColor;
					color: $colorBase;
					display: block;
					text-decoration: none;
					padding: .4rem 0;
					
					&:hover{
						color: $colorLinks;
					}
				}
			}
			
			.current-menu-item {
				a {
					color: $colorAccent;
					font-weight: bold;
				}
			}
		}
	}
}

