@charset "UTF-8";
// ==========================================================================
// Home
// TOPページ
// ==========================================================================

// メインビジュアル
.p-home-hero {
	background: url(images/home_bg@2x.png) no-repeat scroll 50% 50%;
	background-size: cover;
	color: #fff;
	text-align: center;
	position: relative;
}

@media #{$sp} {
	.p-home-hero__logo {
		padding: 2.8rem 0 2.2rem;

		img {
			width: 8rem;
			height: 7rem;
		}
	}

	.p-home-hero__catch {
		font-size: 2.4rem;
		text-shadow: 0px 1px 2px rgba(0,0,0,0.5);
		font-weight: normal;
		margin: 0 0 1.6rem;
	}

	.p-home-hero__exp {
		font-size: 1.1rem;
		text-shadow: 0px 1px 2px rgba(0,0,0,0.5);
		margin: 0 0 1.2rem;
		line-height: 1.73;
		padding: 0 $spSpace;
	}

	.p-home-hero__btn {
		@include table(100%);
		box-sizing: border-box;
		padding: 0 $spSpace 2.8rem;

		.p-home-hero-btn__item {
			@include tableCell(middle);
			padding: 0 .8rem 0 0;

			&:last-child {
				padding: 0 0 0 .8rem;
			}
		}

		a {
			@include animeAll;
			display: block;
			border-radius: 3px;
			border: #fff solid 1px;
			color: #fff;
			text-decoration: none;
			text-align: center;
			text-shadow: 0px 1px 2px rgba(0,0,0,0.5);
			font-size: 1.2rem;
			font-weight: bold;
			width: 100%;
			padding: .6rem 0;

			&:hover {
				background: #fff;
				color: $colorAccent;
				text-shadow: 0px 0px 0px rgba(0,0,0,0);
			}
		}
	}

}

@media #{$tbpc} {
	.p-home-hero__logo {
		padding: 8rem 0 2.8rem;

		img {
			width: 13.6rem;
			height: 12rem;
		}
	}

	.p-home-hero__catch {
		font-size: 4.8rem;
		text-shadow: 0px 1px 2px rgba(0,0,0,0.5);
		font-weight: normal;
		margin: 0 0 1.6rem;
	}

	.p-home-hero__exp {
		font-size: 1.5rem;
		text-shadow: 0px 1px 2px rgba(0,0,0,0.5);
		margin: 0 0 3.2rem;
	}

	.p-home-hero__btn {
		@include ib(center);
		padding: 0 0 12rem;

		.p-home-hero-btn__item {
			@include ibChild;
			padding: 0 2.8rem;
		}

		a {
			@include animeAll;
			display: block;
			border-radius: 3px;
			border: #fff solid 1px;
			color: #fff;
			text-decoration: none;
			text-align: center;
			text-shadow: 0px 1px 2px rgba(0,0,0,0.5);
			font-size: 1.8rem;
			padding: 1.2rem 0;
			width: 29.6rem;

			&:hover {
				background: #fff;
				color: $colorAccent;
				text-shadow: 0px 0px 0px rgba(0,0,0,0);
			}
		}
	}
}


// カノープスとは
@media #{$sp} {
	.p-home-about {
		margin: 0 $spSpace;

		.p-home-about__from {
			@include table(100%);
			background: $colorBg;
			box-sizing: border-box;
			padding: .8rem 1.2rem 1rem 1.2rem;
			margin: 2rem auto 0;
			text-align: left;

			.p-home-about-from__body {
				@include tableCell(middle);
			}

			.p-home-about-from__logo {
				@include tableCell(middle);
				padding: 0 1.2rem 0 0;
				width: 7.6rem;

				img {
					width: 7.6rem;
					height: 6.6rem;
				}
			}

			.p-home-about-from__title {
				font-size: 1.6rem;
				font-weight: bold;
				margin: 0 0 .7rem;
			}
		}
	}
}

@media #{$tbpc} {
	.p-home-about {
		text-align: center;

		.p-home-about__from {
			@include flow($containerWidth - 4rem);
			background: $colorBg;
			box-sizing: border-box;
			padding: 3rem 4rem 3rem 5.2rem;
			margin: 4rem auto 0;
			text-align: left;

			.p-home-about-from__logo {
				float: left;
				padding: 0 4.8rem 0 0;
			}

			.p-home-about-from__title {
				font-size: 2.4rem;
				font-weight: bold;
				margin: 0 0 1.2rem;
			}
		}
	}
}


// ミッション
.p-home-mission {
	background: url(images/home_bg_mission@2x.png) no-repeat scroll 50% 50%;
	background-size: cover;
	text-align: center;
	color: #fff;
}
@media #{$sp} {
	.p-home-mission__title {
		font-size: 1.6rem;
		margin: 0 0 1.5rem;
	}

	.p-home-mission__body {
		list-style: none;
		text-align: left;
		margin: 0 $spSpace;

		li {
			margin: 0 0 1rem;

			&:last-child {
				margin: 0;
			}
		}
	}
}

@media #{$tbpc} {
	.p-home-mission__title {
		font-size: 3.6rem;
		margin: 0 0 4rem;
		padding: 2.4rem 0 0;
		font-weight: normal;
	}

	.p-home-mission__body {
		list-style: none;

		li {
			font-size: 1.8rem;
			margin: 0 0 3rem;

			&:last-child {
				margin: 0;
			}
		}
	}
}


// サービス概要
@media #{$sp} {
	.p-home-service {
		margin: 0 $spSpace;

		.p-home-service-box__item {
			box-sizing: border-box;
			padding: 0 0 2.4rem 0;

			a {
				@include animeAlpha;
				border: #DEE2E8 solid 2px;
				display: block;
				text-decoration: none;
			}
		}

		.p-home-service-box-item__title {
			background: #fff;
			position: relative;
			font-size: 1.6rem;
			font-weight: bold;
			color: $colorAccent;
			padding: .6rem 1.2rem;

			&:before {
				content: "";
				display: block;
				background: url(images/icon_links_box@2x.png) no-repeat scroll 0 0;
				background-size: 7px 12px;
				width: 7px;
				height: 12px;
				position: absolute;
				right: 1.2rem;
				margin: auto 0;
				top: 0;
				bottom: 0;
			}
		}

		.p-home-service-box-item__inner {
			background-size: cover;
			background-position: 50% top;
			background-repeat: no-repeat;
			padding: 10.1rem 0 0;
		}

		.p-home-service-box-item__exp {
			background: rgba(255, 255, 255, 0.84);
			color: $colorBase;
			font-size: 1.1rem;
			line-height: 1.6;
			padding: .8rem 1.2rem;

			br {
				display: none;
			}
		}
	}
}

@media #{$tbpc} {
	.p-home-service-box {
		@include table(100%);

		.p-home-service-box__item {
			@include tableCell(top);
			box-sizing: border-box;
			width: 50%;
			padding: 0 2rem 0 0;

			&:nth-child(even) {
				padding: 0 0 0 2rem;
			}
		}

		a {
			@include animeAlpha;
			border: #DEE2E8 solid 4px;
			display: block;
			text-decoration: none;

			&:hover{
				@include animeAlphaHover(72);
			}
		}

		.p-home-service-box-item__title {
			background: #fff;
			position: relative;
			font-size: 2.4rem;
			font-weight: bold;
			color: $colorAccent;
			padding: .8rem 2.4rem;

			&:before {
				content: "";
				display: block;
				background: url(images/icon_links_box@2x.png) no-repeat scroll 0 0;
				background-size: 10px 16px;
				width: 10px;
				height: 16px;
				position: absolute;
				right: 2rem;
				margin: auto 0;
				top: 0;
				bottom: 0;
			}
		}

		.p-home-service-box-item__inner {
			background-size: cover;
			background-position: 50% top;
			background-repeat: no-repeat;
			padding: 16.2rem 0 0;
		}

		.p-home-service-box-item__exp {
			background: rgba(255, 255, 255, 0.84);
			color: $colorBase;
			font-size: 1.5rem;
			line-height: 1.67;
			padding: .8rem 1.6rem;
		}
	}
}


// 運営メディア
.p-home-media {
	background: $colorBg;
}

@media #{$sp} {
	.p-home-media {
		.p-home-media__item {
			text-align: center;
			margin: 0 0 2.8rem;

			&:last-child {
				margin: 0;
			}

			.p-home-media-item__thumb {
				img {
					width: 10rem;
					border-radius: 10rem;
				}
			}

			.p-home-media-item__body {
				margin: 0 $spSpace;
			}

			.p-home-media-item__title {
				color: $colorAccent;
				font-weight: bold;
				font-size: 1.6rem;
				margin: 1rem 0;
			}

			.p-home-media-item__exp {
				margin: 0 0 1rem;
			}
		}
	}
}

@media #{$tbpc} {
	.p-home-media {
		.p-home-media__item {
			@include table(100%);
			table-layout: fixed;
			margin: 0 0 4rem;

			&:last-child {
				margin: 0;
			}
		}

		.p-home-media-item__thumb {
			@include tableCell(top);
			width: 18.4rem;
			padding: 0 4rem 0 0;

			img {
				border-radius: 18.4rem;
			}
		}

		.p-home-media-item__body {
			@include tableCell(middle);
		}

		.p-home-media-item__title {
			color: $colorAccent;
			font-weight: bold;
			font-size: 2.4rem;
			margin: 0 0 .6rem;
		}

		.p-home-media-item__exp {
			margin: 0 0 1.4rem;
		}
	}
}


// 会社情報
@media #{$sp} {
	.p-home-company{
		margin: 0 $spSpace;

		.p-home-company-list {
			list-style: none;
		}

		.p-home-company-list__item {
			font-size: 1.2rem;
			margin: 0 0 1.6rem;
		}

		.p-home-company-list-item__title {
			color: $colorAccent;
			font-weight: bold;
		}

		.p-home-company__map {
			iframe {
				width: 100%;
				height: 16rem;
			}
		}

		.p-home-company__map-links {
			text-align: right;
			margin: .4rem 0 0;
		}
	}
}

@media #{$tbpc} {
	.p-home-company{
		.p-home-company-list {
			list-style: none;
		}

		.p-home-company-list__item {
			@include table(100%);
			table-layout: fixed;
			border-bottom: #ccc solid 1px;
			padding: 1.3rem 0;
		}

		.p-home-company-list-item__title {
			@include tableCell(middle);
			box-sizing: border-box;
			color: $colorAccent;
			font-weight: bold;
			width: 16rem;
			padding: 0 0 0 .8rem;
		}
		.p-home-company-list-item__contents {
			@include tableCell(middle);
		}

		.p-home-company__map {
			margin: 4rem 0 0;

			iframe {
				width: 100%;
				height: 33.4rem;
			}
		}

		.p-home-company__map-links {
			text-align: right;
		}
	}
}


// 更新情報
.p-home-news {
	background: $colorBg;
}

@media #{$sp} {
	.p-home-news__body {
		list-style: none;
		margin: 0 $spSpace;

		.p-home-news__item {
			margin: 0 0 .2rem;

			a {
				background: #fff;
				display: block;
				text-decoration: none;
				padding: 1rem 1.2rem;
				position: relative;
			}


			.p-home-news-item__date {
				box-sizing: border-box;
				margin: 0 0 .3rem;
				color: $colorLinks;
			}

			.p-home-news-item__title {
				color: $colorBase;
			}
		}
	}
}

@media #{$tbpc} {
	.p-home-news__body {
		list-style: none;

		.p-home-news__item {
			margin: 0 0 .4rem;

			a {
				@include table(100%);
				@include animeAlpha;
				background: #fff;
				text-decoration: none;
				padding: 1.3rem 0;
				position: relative;

				&:after {
					content: "";
					background: url(images/home_icon_news@2x.png) no-repeat scroll 0 0;
					background-size: 10px 16px;
					width: 10px;
					height: 16px;
					position: absolute;
					top: 0;
					bottom: 0;
					right: 1.9rem;
					margin: auto 0;
				}

				&:hover{
					@include animeAlphaHover(72);
				}
			}

			.p-home-news-item__date {
				@include tableCell(top);
				width: 20rem;
				box-sizing: border-box;
				padding: 0 0 0 3.2rem;
				color: $colorLinks;
			}

			.p-home-news-item__title {
				@include tableCell(top);
				color: $colorBase;
				box-sizing: border-box;
				padding: 0 8rem 0 0;
			}
		}
	}
}


// お問い合わせボタン
@media #{$sp} {
	.p-home-contact {
		padding: 2.4rem 0 4rem;
	}
}
@media #{$tbpc} {
	.p-home-contact {
		padding: 6.4rem 0 8rem;
	}
}


// ブログループ
.p-home-blog {
	margin: 2.4rem 0 0;
	text-align: left;
	@media #{$tbpc} {
	}
}

.p-home-blog-item {
	border-bottom: #ccc solid 1px;
	padding: 0 0 1.2rem;
	margin: 0 0 1.2rem;
	@media #{$tbpc} {
		display: flex;
		padding: 0 0 1rem;
		margin: 0 0 1rem;
	}
}
.p-home-blog-item__date {
	@media #{$tbpc} {
		width: 10.4rem;
	}
}
.p-home-blog-item__title {
	flex: 1;
	a {
		color: $colorBase;
		&:hover{
		}
	}
}


