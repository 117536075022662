@charset "UTF-8";
// ==========================================================================
// Box
// ボックスの設定
// ==========================================================================

.c-box {
	@media #{$sp} {
		padding: 0 0 3rem;
	}
	
	@media #{$tbpc} {
		padding: 0 0 8rem;
	}
}
