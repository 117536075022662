@charset "UTF-8";
// ==========================================================================
// Variable
// グローバルな変数を設定。それ以外はそのscssファイルのみで完結。
// ==========================================================================

// -----------------------------------------------------------------
// Font Family
// -----------------------------------------------------------------

// PCのベースフォント
$fontYugo: "游ゴシック体", "Yu Gothic", YuGothic, "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", "ＭＳ Ｐゴシック", sans-serif;

// SPのベースフォント
$fontHirakaku: "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "sans-serif";

// Noto Sans
$fontNoto: 'Noto Sans Japanese', serif;

// Robot
$fontRobot: 'Roboto', sans-serif;


// -----------------------------------------------------------------
// Color
// -----------------------------------------------------------------

// ベースのフォント色
$colorBase: #242424;

$colorBaseSub: #56575A;

$colorGray: #818388;

$colorAccent: #113675;

$colorSub: #67B5B6;

$colorBg: #F1F2F5;

$colorBorder: #DCDCDC;

$colorLinks: #299AD1;

$colorAlert: #E03662;

$colorSubLinks: #14B8BB;

$colorSexMan: #113675;
$colorSexWoman: #E03662;




// -----------------------------------------------------------------
// Scale, Size
// -----------------------------------------------------------------

// コンテナの幅
$containerWidth: 98rem;
// コンテナの小さい幅
$containerWidthSmall: 62rem;

// spの左右幅
$spSpace: 1.6rem;


// -----------------------------------------------------------------
// responsive
// -----------------------------------------------------------------

// スマホ用
$sp: "only screen and (max-width:767px)";

// タブレット&PC用
$tbpc: "only screen and (min-width:768px)";


// -----------------------------------------------------------------
// array
// -----------------------------------------------------------------


